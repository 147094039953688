<template>
  <div
    v-editable="blok"
    class="text-with-icon"
    :class="{
      'd-flex': ['left', 'right'].includes(blok.icon_position),
      'w-60': blok.icon_size === 'small',
      'w-80': blok.icon_size === 'medium',
    }"
  >
    <div
      v-if="['left', 'top'].includes(blok.icon_position)"
      class="text-center"
      :class="{
        'mr-4': ['left'].includes(blok.icon_position),
        'mb-4': isLottie,
      }"
    >
      <Asset
        v-if="blok?.image?.filename"
        :blok
        :class="[
          'mb-4',
          blok?.icon_classes,
          {
            'feature-icon-wrap': blok?.icon_wrapped,
            'md:mr-[20%]': !blok?.centered,
            'why-icon-wrapper': blok?.icon_color === 'primary',
            'usp-box-icon': blok?.icon_color === 'secondary',
          },
        ]"
      />
      <slot name="icon" />
    </div>
    <component
      :is="blok.format || 'h2'"
      v-if="blok.headline"
      :class="[
        blok.headline_classes,
        { 'text-sm-left': !blok.centered, 'text-center': blok.centered },
      ]"
    >
      {{ blok.headline }}
    </component>
    <p
      v-if="blok.text"
      :class="[
        blok.text_classes,
        ,
        { 'text-sm-left': !blok.centered, 'text-center': blok.centered },
      ]"
    >
      {{ blok.text }}
    </p>

    <div v-if="blok.icon_position === 'right'" class="mb-6 text-center">
      <Asset
        v-if="blok?.image?.filename"
        :blok="{ ...blok, text: null }"
        :class="{
          'md:mr-[20%]': !blok.centered,
          'why-icon-wrapper': blok.icon_color === 'primary',
          'usp-box-icon': blok.icon_color === 'secondary',
        }"
      />
    </div>
  </div>
</template>

<script setup>
const utils = useUtils();

const props = defineProps({ blok: Object });
const isLottie = utils.isLottie(props.blok.image?.filename);
</script>

<style lang="scss">
.text-with-icon {
  .feature-icon-wrap {
    img {
      height: 35px;
    }
  }
}
</style>
